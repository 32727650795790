import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`CrossFit Open WOD 19.3`}</strong></p>
    <p>{`For time:`}</p>
    <p>{`200ft DB OH Walking Lunge (50/35`}{`#`}{`)`}</p>
    <p>{`50 DB Box Step Ups (24/20″)`}</p>
    <p>{`50 Strict HSPU’s`}</p>
    <p>{`200ft HS Walk`}</p>
    <p>{`10:00 time cap.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We’ll be throwing down again tonight between 4:30-6:30 for 19.3.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free CrossFit class at 9:00 & 10:00am so bring
a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit the Ville day at Climb Nulu is this Saturday at 11:30.  We
have a sign up sheet by the white board so let us know if you can make
it.  If you have any questions email Daniel.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Next Pop Up Mobility classes with Glenn be this Sunday, March
10th, at 12:00am.  This is free for all members, \\$10 for anyone else,
so pop in if you can make it!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      